import { Spinner } from "@chakra-ui/react";
import classNames from "classnames";
import { CSSProperties } from "react";

export interface FormButtonProps {
  isDisabled?: boolean;
  onPress?(e: React.FormEvent<HTMLButtonElement>): void;
  title: string | JSX.Element;
  isLoading?: boolean;
  outline?: boolean;
  className?: string;
  textStyle?: CSSProperties | undefined;
  buttonClass?: string;
  style?: CSSProperties | undefined;
}

/**
 *
 * This is a button according to the design
 *
 * @param title
 * @param isDisabled
 * @param onPress
 * @param outline
 * @param isLoading
 * @param buttonClass
 * @param textStyle
 * @returns
 */
const FormButton = ({
  title,
  isDisabled,
  onPress,
  outline,
  isLoading,
  className,
  buttonClass,
  style,
  textStyle,
}: FormButtonProps) => {
  return (
    <button
      type='submit'
      className={classNames(
        buttonClass,
        `cursor-pointer relative rounded-[6px] w-full h-[50px] flex flex-row items-center justify-center`,
        {
          "bg-black text-white": !outline,
          "bg-transparent": outline,
        },
      )}
      style={{
        ...style,
        opacity: isDisabled ? 0.4 : 1,
      }}
      onClick={onPress}
      onSubmit={onPress}
    >
      <span
        className={classNames(className ? className : "font-gellix font-[500]", {
          "text-white": !outline,
          "text-black": outline,
        })}
        style={{ ...textStyle }}
      >
        {title}
      </span>
      {isLoading && (
        <div className='ml-2 flex items-center'>
          <Spinner size={"sm"} />
        </div>
      )}
    </button>
  );
};

export default FormButton;
